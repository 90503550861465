var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.typeClass }, [
    _vm._v(" " + _vm._s(_vm.ProRecipeTasteType.getTypeLabel(_vm.typeId)) + " "),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }