<template>
  <div :class="typeClass">
    {{ ProRecipeTasteType.getTypeLabel(typeId) }}
  </div>
</template>

<script>
import { ProRecipeTasteType } from '@/constants/enums';
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  props: {
    typeId: {
      type: String,
      default: 'A'
    }
  },

  setup: (props) => {
    const blockName = 'dp-taste-tag';
    const typeClass = computed(
      () => blockName + '-' + ProRecipeTasteType.getTypeName(props.typeId)
    );

    return {
      ProRecipeTasteType,
      typeClass
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-taste-tag {
  @mixin common-style {
    display: inline-flex;
    place-items: center;
    padding: 4px 5px;
    height: 24px;
    font-size: 10px;
    line-height: 1.4;
    font-weight: normal;
  }

  &-mild-and {
    &-bitter,
    &-acidity {
      @include common-style;

      background-color: variables.$dpGreyF1;
    }
  }

  &-rich-and {
    &-bitter,
    &-acidity {
      @include common-style;

      background-color: variables.$dpGrey66;
      color: variables.$white01;
    }
  }
  &-balance {
    @include common-style;

    background-color: variables.$dpGrey66;
    color: variables.$white01;
  }
}
</style>
